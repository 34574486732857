// Entry point for the build script in your package.json
import '@hotwired/turbo-rails';
import './controllers';

import Alpine from 'alpinejs';
import qrcode from './qrcode';

window.Alpine = Alpine;
Alpine.data('qrcode', qrcode);
Alpine.start();
