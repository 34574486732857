import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="select-fieldset"
export default class extends Controller {
  static targets = ["select", "template", "fieldset"]

  connect() {
    this.update()
  }

  update() {
    const value = this.selectTarget.value
    const template = this.templateTargets.find(t => t.dataset.type == value)
    this.fieldsetTarget.innerHTML = template.innerHTML
  }
}
